
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import KonfirmasiLegal from "@/components/1crm/legal/konfirmasiLegal/konfirmasiLegal.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    KonfirmasiLegal,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Konfirmasi Legal", ["Data"]);
  },
});
